import React from "react";
import highlightedDatesData from '../specialDays/highlightedDates.json';

export const Day = ({ day, onClick }) => {
  // Check if the current date is highlighted
  const isHighlighted = highlightedDatesData.highlightedDates.some(
    highlightedDate => highlightedDate.date === day.date
  );

  // Get the highlighted date object
  const highlightedDate = highlightedDatesData.highlightedDates.find(
    highlightedDate => highlightedDate.date === day.date
  );

  // Define the class name for the day
  const className = `day ${day.value === 'padding' ? 'padding' : ''} ${day.isCurrentDay ? 'currentDay' : ''} ${isHighlighted ? 'highlighted' : ''}`;

  return (
    <div onClick={onClick} className={className}>
      {day.value === 'padding' ? '' : day.value}

      {/* Display event if it exists for the day */}
      {day.event && <div className='event'>{day.event.title}</div>}

      {/* Display text and icon for highlighted dates */}
      {isHighlighted && (
        <div className="highlight-container">
          <p className='highlightText'>
            {highlightedDate.text}
          </p>
          {highlightedDate.src && (
            <div className="highlight-container">
            <div className="tooltip-container">
              <img 
                className='highlightedIcon'
                src={highlightedDate.src}
                alt="Icon"
              />
            </div>
            <div className="tooltip-text">
              <h4>{highlightedDate.head}</h4>
              <p>{highlightedDate.popup}</p>
            </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
