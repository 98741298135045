import React, { useEffect, useState } from 'react';
import highlightedDatesData from '../specialDays/highlightedDates.json';

export const useDate = (events, nav) => {
  const [dateDisplay, setDateDisplay] = useState('');
  const [days, setDays] = useState([]);

  useEffect(() => {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dt = new Date();

    if (nav !== 0) {
      dt.setMonth(new Date().getMonth() + nav);
    }

    const day = dt.getDate();
    const month = dt.getMonth();
    const year = dt.getFullYear();

    const firstDayOfMonth = new Date(year, month, 1);
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dateString = firstDayOfMonth.toLocaleDateString('en-us', {
      weekday: 'long',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });

    setDateDisplay(`${dt.toLocaleDateString('en-us', { month: 'long' })} ${year}`);
    const paddingDays = weekdays.indexOf(dateString.split(', ')[0]);

    const daysArr = [];

    for (let i = 1; i <= paddingDays + daysInMonth; i++) {
      const dayString = `${(month + 1).toString().padStart(2, '0')}-${(i - paddingDays).toString().padStart(2, '0')}`;
      const isHighlighted = highlightedDatesData.highlightedDates.some(date => date.date === dayString || date.date.endsWith(dayString));
      daysArr.push({
        value: i - paddingDays > 0 ? i - paddingDays : '',
        isCurrentDay: i - paddingDays === day && nav === 0,
        isHighlighted: isHighlighted,
        date: dayString,
        event: events.find(event => event.date === dayString)
      });
    }
    
    setDays(daysArr);
  }, [events, nav]);

  return {
    days,
    dateDisplay,
  };
};
