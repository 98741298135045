import React from 'react';
import './Header.css';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";

const Header = ({ goNextMonth, goPreviousMonth, isOnFirstMonth, isOnLastMonth }) => {
  const shareOnFacebook = () => {
    const url = encodeURIComponent(window.location.href);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
  };
  
  /*const shareOnInstagram = () => {
    const url = 'https://www.instagram.com/fortude.co/';
    window.open(url, '_blank');
  };*/
  
  const shareOnLinkedin = () => {
    const url = encodeURIComponent(window.location.href);
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, '_blank');
  };
  return (
    <div className='header'>
      <div className="logo-container">
        <img className='logo' src="/assets/logo/logo.png" alt="logo" />
      </div>
      <div className='social-media'>
        <p onClick={shareOnFacebook}><FaFacebookF /></p>
        {/*<p onClick={shareOnInstagram}><FaInstagram /></p>*/}
        <p onClick={shareOnLinkedin}><FaLinkedin /></p>
      </div>
      <div className="navigation-buttons">
        <button onClick={goPreviousMonth} disabled={isOnFirstMonth}><MdNavigateBefore /></button>
        <button onClick={goNextMonth} disabled={isOnLastMonth}><MdNavigateNext /></button>
      </div>
    </div>
  );
}

export default Header;