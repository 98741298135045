import React from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";

import './PopupMessage.css';

const PopupMessage = ({ onClose }) => {
  const handleCloseClick = () => {
    onClose();
  };

  return (
    <div className="popup-container">
      <div className="popup-message">
        <h2>About Sound Therapy:</h2>
        <p>‘Shutterbugs’, a Fortude initiative, champions the art of photography among our employees, providing a platform to showcase their passion. More than just stunning captures, it's a celebration of the rich diversity within our global teams spanning four continents. Each year, meticulously chosen, just 12 images earn a coveted spot on Fortude's calendar, which have been integrated into Sound Therapy.</p>
        <p className='calender-close' onClick={handleCloseClick}><IoIosCloseCircleOutline /></p>
      </div>
      <div className='overlay'></div>
    </div>
  );
};

export default PopupMessage;
