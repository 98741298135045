import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';


function App() {
  window.onload = function() {
    setTimeout(function() {
        window.scrollTo(0, 1);
    }, 1000); // Adjust the delay as needed
  };
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='*' element={<div>404 Not Found</div>}></Route>
          </Routes>
        </div>
      </Router>
    </div>
    
  );
}

export default App;


/*import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';

function App() {
  // Get today's date
  const currentDate = new Date();
  // Define the date after which the website will be blocked
  const blockedDate = new Date('2024-06-30');

  // Function to render content based on date
  const renderContent = () => {
    // Check if current date is after the blocked date
    if (currentDate > blockedDate) {
      return (
        <div style={{ backgroundColor: 'black', color: 'white', textAlign: 'center', padding: '20px', width: '100%', height: '100vh' }}>
          <h1>This website is temporarily down</h1>
          <p style={{ color: 'green' }}>You need to contact developers A$AP</p>
        </div>
      );
    } else {
      return (
        <Router>
          <div>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='*' element={<div>404 Not Found</div>} />
            </Routes>
          </div>
        </Router>
      );
    }
  };

  return (
    <div className="App">
      {renderContent()}
    </div>
  );
}

export default App;*/