import React from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

export const CalendarHeader = ({ onNext, onBack, dateDisplay }) => {
  return (
    <div id="header">
      <div id="monthDisplay">{dateDisplay}</div>
      <div className="calander-slide">  
        <p id="backButton" onClick={onBack}> <MdNavigateBefore/></p>
        <p id="nextButton" onClick={onNext}> <MdNavigateNext/></p>
      </div>
    </div>
  )
}