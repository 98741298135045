import React, { useState, useEffect } from "react";
import { CalendarHeader } from "../CalenderHeader/CalendarHeader";
import { Day } from "../Day/Day";
import { useDate } from "../hooks/useDate";
import './Calender.css'

export const Calender = () => {
  const [nav, setNav] = useState(0);
  const [clicked, setClicked] = useState();
  const [events, setEvents] = useState(
    localStorage.getItem('events') ? 
      JSON.parse(localStorage.getItem('events')) : 
      []
  );
  
  const currentYear = new Date().getFullYear();
  const eventForDate = date => events.find(e => e.date === date);
  
  useEffect(() => {
    localStorage.setItem('events', JSON.stringify(events));
  }, [events]);

  const { days, dateDisplay } = useDate(events, nav);

  const handleBack = () => {
   
    const currentDate = new Date(dateDisplay);
    
   
    if (currentDate.getMonth() !== 0 || currentDate.getFullYear() > currentYear) {
      setNav(nav - 1);
    }
  };
  
  return(
    <div className="calender-container">
      <div id="container">
        <CalendarHeader 
          dateDisplay={dateDisplay}
          onNext={() => setNav(nav + 1)}
          onBack={handleBack}
        />

        <div id="weekdays">
          <div>SU</div>
          <div>MO</div>
          <div>TU</div>
          <div>WE</div>
          <div>TH</div>
          <div>FR</div>
          <div>SA</div>
        </div>

        <div id="calendar">
          {days.map((d, index) => (
            <Day
              key={index}
              
              day={d}
              onClick={() => {
                if (d.value !== 'padding') {
                  setClicked(d.date);
                }
              }}
              
            />
          ))}
        </div>

      </div>
    </div>
  );
};